<template>
  <Transition name="modal-fade">
    <div class="popup-holder-benchmark">
      <div class="popup-inner w-50 p-30" @scroll="scrollStopper">
        <div class="flex row ml-0 mr-0 mb-30">
            <h2 v-if="!edit.isNameEditVisible" class="benchmark-name">{{data.name}}</h2>
            <input v-if="edit.isNameEditVisible" class="input-name pl-15 mb-15 w-100" type="text" placeholder="Benchmark Name" v-model="data.name" @focusout="nameValidator" @keypress="validations.isInvalidName=false">
            <div class="error-name pt-5 pb-5 pl-15 pr-15 mb-15 w-100" v-if="validations.isInvalidName">Benchmark name is required</div>
            <div v-if="!edit.isNameEditVisible" class="button-solid-grey-30 flex w-10 jc-c ai-c mr-10 ml-20" @click="edit.isNameEditVisible=true">
                <span>edit</span>
            </div>
        </div>
        <div>
          <div class="popup-label-create ml-5 mt-5">Description</div>
          <div class ="flex col ml-5">
            <p v-if="!edit.isDescriptionEditVisible"  class="info-benchmark">{{data.info}}</p>
            <input v-if="edit.isDescriptionEditVisible" class="input-description pl-15 mb-15 w-100" type="text" placeholder="Description" v-model="data.info"  @focusout="edit.isDescriptionEditVisible=false">
            <div v-if="!edit.isDescriptionEditVisible" class="button-solid-grey-30 flex w-10 jc-c ai-c mr-10 mt-10" @click="edit.isDescriptionEditVisible=true">
              <span>edit</span>
            </div>
          </div>
          <div class="popup-label-create ml-5 mt-5">Upload an image</div>
          <image-uploader @image-loaded="onImageLoaded" :image="data.existingImagePath" @image-deleted="onImageDelete"></image-uploader>

          <div class="popup-label-create ml-5 mt-15">Loop</div>
          <div class="slidecontainer">
            <input type="range" min="1" max="99" class="slider mt-5" v-model="data.loop">
            <span class="ml-10 caption">{{data.loop}}x</span>
          </div>
          <div class="popup-label-create ml-5 mt-10">Shared</div>
          <div class="checkbox-shared flex row ml-0">
            <input type="checkbox" name="shared" style="width:17px;height:17px" v-model="data.isShared">
            <label for="shared" class="pl-5 caption">will be visible to others and can be shared</label>
          </div>
          <div class="popup-label-create ml-5 mt-10">Selected tests
            <span class="block green ml-10">{{tests.selectedTests.length}}</span>
          </div>
          <div class="ml-10 mt-5 mb-5 caption">Tests that will be executed when running <span v-if="data.name" >"{{data.name}}"</span></div>
          <SlickList v-model:list="tests.selectedTests" axis="y" class="long-list drop-zone" group="tests">
            <SlickItem v-for="(item, index) in tests.selectedTests" class="item drag-el" :key="item.id" :index="index" :item="item">
              <test-list-tile :testItem="item" />
            </SlickItem>
          </SlickList>

          <div class="popup-label-create ml-5 mt-10">Available tests
            <span class="block red ml-10">{{tests.availableTests.length}}</span>
          </div>
          <div class="ml-10 mt-5 mb-5 caption">Tests that are available within the strike tool</div>
          <SlickList v-model:list="tests.availableTests" axis="y" class="long-list drop-zone" group="tests">
            <SlickItem v-for="(item, index) in tests.availableTests" class="item drag-el" :key="item.id" :index="index" :item="item">
              <test-list-tile :testItem="item" />
            </SlickItem>
          </SlickList>


          <div class="error-tests pt-5 pb-5 pl-15 pr-15 mb-15 mt-15 w-100" v-if="validations.isInvalidTests">Minimum of one test is required</div>
          <div class="buttons flex jc-fe mt-20">
             <div class="button-solid-grey-40 flex w-15 jc-c ai-c mr-15" @click="$emit('close')">
              <span>Cancel</span>
            </div>
            <div class="button-solid-grey-40 flex w-15 jc-c ai-c mr-15" @click="popup.deleteConfirmationPopupVisible=true">
              <span>Delete</span>
            </div>
            <div class="button-solid-black-40 flex w-15 jc-c ai-c" @click="updateBenchmark()">
              <span>Save</span>
            </div>
          </div>
        </div>
      </div>
        <confirmation-popup v-if="popup.deleteConfirmationPopupVisible" operation="delete" name="Benchmark" @yes="deleteBenchmark()" @no="popup.deleteConfirmationPopupVisible = false">
        </confirmation-popup>
    </div>
  </Transition>
</template>

<script setup>
import { SlickList, SlickItem } from 'vue-slicksort';
import {reactive, defineEmits, defineProps, watch} from 'vue'
import { put, get, remove, post } from "@/lib/api.js"
import {TestListTile, ConfirmationPopup, ImageUploader} from "@/components"

const emit = defineEmits(['close'])

const validations = reactive({
  isInvalidName: false,
  isInvalidTests: false,
  isInvalidData: function() {
    this.isInvalidTests = !tests.selectedTests.length
    this.isInvalidName = data.name == ''
    return (this.isInvalidTests || this.isInvalidName)
  }
})

const edit = reactive({
  isNameEditVisible: false,
  isDescriptionEditVisible: false
})
const tests = reactive({
  availableTests: [],
  selectedTests: []
})

const data = reactive({
  name: '',
  info: '',
  isShared: false,
  loop: 1,
  existingImagePath: ''
})

let imageObj;
function onImageLoaded(image){
  imageObj = image;
}

function onImageDelete(){
  imageObj = null
  data.existingImagePath = ''
}

// @todo ref was not working for me?
// replaced ref with reactive, ref working as expected in my env but not sure about glitch
const popup = reactive({
  deleteConfirmationPopupVisible: false
})

const props = defineProps(['tileId'])

watch(() => props.tileId, async (id) => {
  data.id = id
  try {
    let res = await get('users/me/tests', true)
    const totalTests = res.data;
    res = await get(`users/me/benchmarks/${data.id}`, true);

    data.name = res.data.name
    data.info = res.data.info
    data.existingImagePath = res.data.image;
    tests.selectedTests = res.data.tests
    tests.availableTests = totalTests.filter(item => {
       let include = true;
       for (let i=0; i< res.data.tests.length;i++){
         if (item.id == res.data.tests[i].id){
           include = false
           break;
         }
       }
       return include
    })
    data.isShared = res.data.isShared
    data.loop = res.data.loop ? res.data.loop : 1;
  }
  catch(error) {
    console.error("dataFetchError : " + error)
  }
}, { immediate: true });

function nameValidator(event){
  event.preventDefault();
  if(data.name == ''){
    validations.isInvalidName = true
  } else {
    validations.isInvalidName = false;
    edit.isNameEditVisible = false
  }
}

function scrollStopper(event){
  event.stopPropagation();
}

async function deleteBenchmark(){
  try{
     const benchmark = await remove(`users/me/benchmarks/${data.id}`, true);
    if(benchmark.status == "success") {
      emit('close', true)
    }
  } catch(error) {
    console.error("Error while deleting benchmark ", error)
  }
}

async function updateBenchmark() {
  if (validations.isInvalidData()) {
    return
  }
  try {

   // upload image if modified and available
    if(imageObj){
      const imageRes = await post('users/me/images/benchmark', true, imageObj);
      data.image = imageRes.data.imagePath
    } else if (!data.existingImagePath.length){ //incase image itself is removed
      data.image = '';
    }

     data.tests = tests.selectedTests.map( item => {
      return item.id;
    })

    const benchmark = await put(`users/me/benchmarks/${data.id}`, true, data);
    if(benchmark.status == "success") {
      emit('close', true)
    }
  } catch (error) {
    console.error('error updating benchmark:', error);
  }
}

</script>

<style scoped>

.info-benchmark {
  font-size: 20px;
  line-height: 25px;
  word-break: break-word;
}

.benchmark-name {
  max-width: 80%;
  overflow-x: hidden;
}
.drop-zone {
  padding: 20px;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}

.drag-el {
  margin-bottom: 10px;
}

::-webkit-scrollbar {
    display: none;
}

.item {
  z-index: 99999;
}

.slidecontainer {
  width: 40%;
}

.slider {
  -webkit-appearance: none;
  height: 12px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fafafa;
  border: 1px solid black;
  cursor: pointer;
}

.block {
    padding: 4px 10px 4px 10px;
    height: 12px;
    border-radius: 4px;
    font-size: 10px;
    color: white;
}
.green {
  background: #0f9f61;
}
.red {
  background: #ff645c;
}

.caption {
  color: #a8a8a8;
}
</style>