<template>
  <div class="flex w-100 center-xs mt-20 mb-30">
    <div class="flex col col-xs-10 col-md-6">
      <h1 class="mb-10">Sign In</h1>
      <h3 class="mb-30">Start creating benchmarks and track your results</h3>
<!--      <div class="button-solid-blue-70 flex jc-c ai-c mb-30" @click="signInOption('comcast')">-->
<!--        <img class="mr-15" src="@/assets/comcast-icon.png" alt="comcast-icon">-->
<!--        <span>Sign in with Comcast</span>-->
<!--      </div>-->
<!--      <p class="mb-25">or Sign in with Email</p>-->
      <template v-if="emailSignIn" class="flex jc-c ai-c mb-30">
        <form>
          <input type="text" placeholder="example@mail.com" class="input-mail pl-15 mb-15 w-100" :class="!data.isValidEmail ? 'error' : ''" v-model="data.userEmail" @click="focusInput" @focusout="validateEmail()">
          <div class="error-mail pt-5 pb-5 pl-15 pr-15 mb-15 w-100" v-if="!data.isValidEmail">Not a valid Email</div>
          <input type="password" placeholder="password" class="input-password pl-15 mb-15 w-100" v-model="data.password" v-on:keyup.enter="signInAction">
        </form>
      </template>
      <div class="flex row between-xs ml-0 pb-15 mr-0">
        <div class="checkbox-remember flex row ml-0">
          <input type="checkbox" name="remCheckB" id="remCheckB">
          <label for="remCheckB" class="pl-5 remCheck">Remember me</label>
        </div>
        <a class="forget" @click="forgetPass">Forget password?</a>
      </div>
      <div class="error-login pt-5 pb-5 mb-15 pl-15" v-if="!data.isValidCredentials">Email or Password is wrong</div>
      <div class="button-solid-green-70 flex jc-c ai-c" @click="signInAction">
        <span>Sign in</span>
      </div>
      <div class="noAccount mt-10">Don't have an account? <a class="register" @click="register">Register</a></div>
    </div>
  </div>
</template>

<script setup>
import { emailValidator } from '../lib/utils'
import router from '@/lib/router.js'
import { computed, reactive, onBeforeMount, inject, defineEmits } from 'vue'
import {post} from './../lib/api.js'
import { Notify  } from 'vant';

const data = reactive({
  userEmail: '',
  password: '',
  comcastSignIn: false,
  isValidEmail: true,
  isValidCredentials: true
})

const emailSignIn = computed(() => {
    return true //!this.comcastSignIn;
})

const emit = defineEmits(['openRegister'])

async function signInAction() {
    try {
        if(data.isValidEmail){
          data.isValidCredentials = true;
          const res = await post('/login', false, {email: data.userEmail, password: data.password});
          localStorage.setItem('token', res.data.token)
          localStorage.setItem('user', JSON.stringify(res.data))
          router.push({path: 'benchmarks'})
        }
    } catch (error) {
        console.error('error signing in', error);
        data.isValidCredentials = false;
    }
}
// function signInOption(opt) {
//     data.comcastSignIn = opt == 'comcast'
// }

const disableNonMVPFeatures = inject('disableNonMVPFeatures')
function forgetPass() {
  disableNonMVPFeatures ? showNotify() : ''  //Handles forget password
}
function validateEmail() {
    data.isValidEmail = emailValidator(data.userEmail)
}

onBeforeMount(async () => {
  localStorage.removeItem('token')
  localStorage.removeItem('user')
  localStorage.removeItem('activeMenuIndex')
})

const infoMessage = inject('disabledFeaturesMessage')
function showNotify() {
  Notify ({type: 'warning', message: infoMessage, duration:1500})
}

function register(){
  disableNonMVPFeatures ? showNotify() : emit('openRegister')
}
</script>