<template>
  <div class="tile-list-item mb-30">
    <div class="tile-content flex">
      <div class="tile-image">
        <img class="blurred" :src="!props.testItem.image ? logo : image" >
        <img class="image" :src="!props.testItem.image ? logo : image" >
      </div>
      <div class="tile-meta">
        <div class="tile-title">{{props.testItem.name}}</div>
        <div class="tile-subtitle">{{props.testItem.info}}</div>
        <div class="tile-measurements">
          <div class="tile-label">
            Calculations will be based on
          </div>
          <div class="tile-measurement" v-for="(test, index) in props.testItem.resultTypes.slice(0,4)" :key="index">
            {{test}}
          </div>
          <div v-if="props.testItem.resultTypes.length > 3" class="tile-measurement">
            {{props.testItem.resultTypes.length - 4}} more
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, inject} from 'vue';
const props = defineProps(['testItem'])
const logo = require('@/assets/train-icon.png')
const imageServerUrl = inject('imageServerUrl')
const image = imageServerUrl + props.testItem.image
</script>
