<template>
  <div class="tile-device mb-30">
    <div class="tile-content">
      <div class="tile-image">
        <img class="blurred" :src="props.device.image == '' ? logo : imageServerUrl + props.device.image">
        <img class="image" :src="props.device.image == '' ? logo : imageServerUrl + props.device.image">
        <div class="tile-edit" @click="navigateToEdit">Edit</div>
      </div>
      <div class="tile-title">{{device.name || "no title"}}</div>
      <div class="tile-props">
        <div class="tile-prop">{{props.device.resolution}}</div>
        <div class="tile-prop">{{props.device.model}}</div>
        <div class="tile-prop">{{props.device.ram}}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, inject } from 'vue';
import router from '@/lib/router'
const props = defineProps(['device', 'index'])
const logo = require('@/assets/setup_box.png')
const imageServerUrl = inject('imageServerUrl')
import { Notify  } from 'vant';

const disableNonMVPFeatures = inject('disableNonMVPFeatures')
const infoMessage = inject('disabledFeaturesMessage')

function showNotify() {
  Notify ({type: 'warning', message: infoMessage, duration:1500})
}

function navigateToEdit() {
    disableNonMVPFeatures ? showNotify() : router.push({path: `/devices/update/${props.device.id}`})
}

</script>