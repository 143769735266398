<template>
  <div class="header flex middle-xs center-xs w-100">
    <div class="flex w-90">
      <div class="flex col-xs-10 col-md-11">
        <div class="mr-30">
          <img src="@/assets/logo.png/" class="logoImg mr-30" @click="navigateToHome">
        </div>
        <div class="flex">
          <div class="mr-30 pt-10 pb-10" v-for="(menuItem, index) in menus" :key="`menu${index}`">
            <div class="button-text-header" :class="{'active' : index == data.activeMenuIndex}" @click="goTo(menuItem, index)">{{menuItem.label}} </div>
          </div>
        </div>
      </div>
<!--      <template v-if="nonLogged">-->
<!--        <div class="flex jc-c ai-c middle-xs center-xs col-xs-2 col-md-1">-->
<!--          <div class="button-solid-black-40 flex jc-c ai-c pr-15 pl-15" @click="actionHandler()">-->
<!--            {{getAction}}-->
<!--          </div>-->
<!--        </div>-->
<!--      </template>-->
<!--      <template v-else>-->
      <template v-if="!nonLogged">
        <div class="flex middle-xs center-xs col-xs-2 col-md-1">
          <van-popover v-model:show="data.showOptions" :actions="options" theme="dark" @select="onSelect">
            <template #reference>
             <div class="button-round-green-40 flex jc-c ai-c">{{data.userLogo}}</div>
            </template>
          </van-popover>
        </div>
      </template>
    </div>
  </div>
<!--    <div class="flex w-100 headerC">-->
<!--        <div class="flex middle-xs w-100" v-if="!isMobileView">-->
<!--            <div class="flex logoC middle-xs center-xs col-xs-3">-->
<!--                <img src="@/assets/strike_logo.jpeg/" class="logoImg">-->
<!--                <div class="logoLabel ml-5">Strike</div>-->
<!--            </div>-->
<!--            <div class="flex middle-xs menuC col-xs-7" v-if="menuItemsExists">-->
<!--                <div class="flex mr-30 ml-10" v-for="(menuItem, index) in menus" :key="`menu${index}`">-->
<!--                    <div class="menuLabel" :class="{'active' : index == data.activeMenuIndex}" @click="goTo(menuItem, index)">{{menuItem.label}} </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <template v-if="nonLogged">-->
<!--                  <div class="flex regC middle-xs" :class="menuItemsExists ? 'center-xs col-xs-2' : 'col-xs-9 end-xs pr-30'" @click="actionHandler()">-->
<!--                  {{getAction}}-->
<!--                </div>-->
<!--            </template>-->
<!--            <template v-else>-->
<!--                 <div class="flex col-xs-2 center-xs middle-xs">-->
<!--                    <div class="loggedInUser buttonC" @click="data.expandUserInfo = !data.expandUserInfo">{{data.userLogo}}</div>-->
<!--                    <div class="flex userInfoC" :class="{'expand': data.expandUserInfo}">-->
<!--                        <div class="flex col w-100">-->
<!--                            <div class="menuLabel mobile p-5" @click="logout">Logout</div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </template>-->
<!--        </div>-->
<!--        <div v-if="isMobileView && !menuItemsExists" class="flex w-100 middle-xs">-->
<!--             <div class="flex middle-xs col-xs-6 mobile">-->
<!--                <img src="@/assets/strike_logo.jpeg/" class="logoImg">-->
<!--                <div class="logoLabel ml-5 mobile" >Strike</div>-->
<!--            </div>-->
<!--            <template v-if="nonLogged">-->
<!--                <div class="flex regC middle-xs" :class="menuItemsExists ? 'center-xs col-xs-2' : 'col-xs-6 end-xs pr-30'" @click="actionHandler()">-->
<!--                    {{getAction}}-->
<!--                </div>-->
<!--            </template>-->
<!--        </div>-->
<!--        <div v-if="isMobileView && menuItemsExists" class="flex w-100 middle-xs">-->
<!--            <div class="flex col-xs-4">-->
<!--                <img src="@/assets/hamburger.png" class="logoImg" @click="data.expandMenu = !data.expandMenu">-->
<!--                <div class="flex mobileMenuC" :class="{'expand': data.expandMenu}">-->
<!--                    <div class="flex col w-100 pt-5">-->
<!--                         <div class="flex w-100 p-5" v-for="(menuItem, index) in menus" :key="`menu${index}`">-->
<!--                            <div class="menuLabel mobile" :class="{'active' : index == data.activeMenuIndex}" @click="goTo(menuItem, index)">{{menuItem.label}} </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="flex col-xs-4 logoLabel">Strike</div>-->
<!--            <div class="flex col-xs-4 center-xs middle-xs">-->
<!--                <div class="loggedInUser buttonC"  @click="data.expandUserInfo = !data.expandUserInfo">{{data.userLogo}}</div>-->
<!--                 <div class="flex userInfoC mobile" :class="{'expand': data.expandUserInfo}">-->
<!--                        <div class="flex col w-100">-->
<!--                            <div class="menuLabel mobile p-5" @click="logout">Logout</div>-->
<!--                        </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</template>
<script setup>
import router from '@/lib/router';
// import { isMobile } from '@/lib/utils';
import { computed, defineProps, reactive, onBeforeMount, inject } from 'vue';
import { Notify  } from 'vant';

const ACTIVE_MENU_INDEX_KEY = "activeMenuIndex"
const data = reactive({
    activeMenuIndex: -1,
    expandMenu: false,
    userLogo: "D",
    expandUserInfo: false,
    showOptions: false
})

const options = [
  {
    name: "myAccount",
    text: "My Account"
  },
  {
    name: "logout",
    text: "Logout"
  }
]

const props = defineProps(['menus', 'register', 'login'])

// const isMobileView = computed(() => {
//     return isMobile()
// })

const nonLogged = computed(() => {
    return props.register || props.login
})
// const getAction = computed(() => {
//     return props.register ? 'Register' : props.login ? 'Login' : ''
// })
// const menuItemsExists = computed(() => {
//     return props.menus && props.menus.length > 0 ? true : false
// })
function goTo(item, ind) {
    if( item.path.match('/devices') && disableNonMVPFeatures ) {
      showNotify()
    } else {
      router.push({path: `${item.path}`})
      setActiveMenuIndex(ind)
      data.expandMenu = false
    }

}
// function actionHandler() {
//     router.push({path: props.register ? 'register' : props.login ? '/' : ''})
// }
// function logout(){
//     localStorage.clear() //Clear all local storage items
//     router.push({path: '/'})
// }

onBeforeMount(async()=> {
    const user = JSON.parse(localStorage.getItem('user'));
    if(user && user.name){
        data.userLogo = user.name.charAt(0).toUpperCase();
    }
    const activeMIndex = localStorage.getItem(ACTIVE_MENU_INDEX_KEY)
    data.activeMenuIndex = activeMIndex ? activeMIndex : 0
})

function setActiveMenuIndex(ind){
  data.activeMenuIndex = ind
  localStorage.setItem(ACTIVE_MENU_INDEX_KEY, ind)
}

function navigateToHome(){
  setActiveMenuIndex(0)
  router.push({path:'/benchmarks'})
}

const disableNonMVPFeatures = inject('disableNonMVPFeatures')
function onSelect(item){
  switch(item.name){
    case 'myAccount':
      disableNonMVPFeatures ? showNotify() : '' //To do, user account information
      break;
    case 'logout':
      localStorage.removeItem(ACTIVE_MENU_INDEX_KEY)
      router.push({path: '/'})
      break;
    default:
      console.log('default option')
 }
}

const infoMessage = inject('disabledFeaturesMessage')
function showNotify() {
  Notify ({type: 'warning', message: infoMessage, duration:1500})
}
</script>
<style scoped>

.logoImg:hover{
  cursor: pointer;
}
</style>