<template>
  <Transition name="modal-fade">
    <div class="popup-holder-benchmark">
      <div class="popup-inner w-50 p-30">
        <h2 class="mb-20">Create account</h2>
        <div class="label ml-5 pb-10">name</div>
        <input class="input-name pl-15 mb-15 w-100" type="text" placeholder="Your Name" v-model="data.name">
        <div class="label ml-5 pb-10">e-mail</div>
        <input class="input-mail pl-15 mb-15 w-100" type="text" placeholder="Your Email" v-model="data.email" @focusout="validateEmail()">
        <div class="error-mail pt-5 pb-5 pl-15 pr-15 mb-15 w-100" v-if="validations.isInvalidEmail">Not a valid Email</div>
        <div class="label ml-5 pb-10">password</div>
        <input class="input-password pl-15 mb-15 w-100" type="password" placeholder="Password" v-model="data.password" @focusout="validatePassword()">
        <div class="error-password pt-5 pb-5 pl-15 pr-15 mb-15 w-100"  v-if="validations.isInvalidPassword" >Password must contain a minimum of eight characters, at least one uppercase letter, one lowercase letter, and one number.</div>
        <div class="label ml-5 pb-10 w-100">repeat password</div>
        <input class="input-password pl-15 mb-15 w-100" type="password" placeholder="Repeat Your Password" v-model="data.rePassword" @focusout="validateRePassword()" v-on:keyup.enter="signUp()">
        <div class="error-password-match pt-5 pb-5 pl-15 pr-15 mb-15 w-100"  v-if="validations.isPasswordMismatch">Password mismatch</div>
        <div class="error-mail pt-5 pb-5 pl-15 pr-15 mb-15 w-100"  v-if="validations.isEmailExists">Email already registered! Try login</div>
        <div class="error-required pt-5 pb-5 pl-15 pr-15 mb-15 w-100"  v-if="!validations.isValidData">All the fields are required!</div>
        <div class="buttons mt-5">
          <div class="button-solid-green-70 flex jc-c ai-c" @click="signUp()">
            <span>Sign Up</span>
          </div>
          <div class="button-solid-black-70 flex jc-c ai-c mt-15" @click="$emit('close')">
            <span>Cancel</span>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { emailValidator, passwordValidator } from "@/lib/utils"
import { reactive } from 'vue'
import router from "@/lib/router.js"
import { post } from "@/lib/api.js"

const validations = reactive({
  isInvalidEmail: false,
  isInvalidPassword: false,
  isEmailExists: false,
  isPasswordMismatch: false,
  isValidData: true,
  validation: function() {
    return (data.name && data.email && data.password && data.rePassword) ? true : false
  }
})

const data = reactive({
  name: '',
  email: '',
  password: '',
  rePassword: '',
})

async function signUp() {
  if (!validations.validation()) {
    data.isValidData = false
    return
  }
  data.isValidData = true
  if(validations.passwordMismatch || validations.invalidEmail || validations.invalidPassword) {
    return
  }
  try {
    const user = await post('users', false, data);
    if(user.status == "success") {
      router.push({path: '/'})
    }
  } catch (error) {
    console.error('error signing up:', error);
    if (error.response.data.errors.includes("emailExists")) validations.isEmailExists = true
  }
}
function validateEmail() {
  validations.isInvalidEmail = !emailValidator(data.email)
}
function validatePassword() {
  validations.isInvalidPassword = !passwordValidator(data.password)
}
function validateRePassword() {
  validations.isPasswordMismatch = data.password !== data.rePassword
}
</script>