<template>
  <div class="tile-benchmark mb-30">
    <div class="tile-content">
      <div class="tile-image">
        <img class="blurred" :src="!props.benchmarkItem.image ? logo : imageServerUrl + props.benchmarkItem.image">
        <img class="image" :src="!props.benchmarkItem.image ? logo : imageServerUrl + props.benchmarkItem.image">
        <div class="tile-url" @click="benchmarkURL">Copy URL</div>
        <div class="tile-edit" @click="navigateToDetails">Edit</div>
      </div>
      <div class="tile-title">{{props.benchmarkItem.name || "no title"}}</div>
      <div class="tile-subtitle">{{props.benchmarkItem.info || "no description"}}</div>
      <div class="tile-tests">
        <div class="tile-test" v-for="(test, index) in testList.slice(0,4)" :key="index">
          {{test.name}}
        </div>
        <div v-if="testList.length > 3" class="tile-test">
          {{testList.length - 4}} more
        </div>
      </div>
    </div>
    <!--
    <van-notify v-model:show="showCopyNotification" type="success" class="dashboard-notify">
        <span>Benchmark URL Copied.</span>
    </van-notify>
    -->
  </div>

</template>
<script setup>
import { defineProps, computed, inject, defineEmits} from 'vue'
import { Notify  } from 'vant';

const logo = require('@/assets/plane-icon.png')
const imageServerUrl = inject('imageServerUrl')
const props = defineProps(['benchmarkItem', 'index'])
const emit = defineEmits(['openBenchmark'])
const benchmarkBaseUrl = inject('benchmarkBaseUrl')


const testList = computed(() => {
    return props.benchmarkItem.tests
})

function navigateToDetails(){
  emit('openBenchmark', {id: props.benchmarkItem.id})
}

function benchmarkURL(event){
    event.preventDefault();
    event.stopPropagation();
    window.navigator.clipboard.writeText(benchmarkBaseUrl+props.benchmarkItem.executionKey)
    showNotify()
}

function showNotify() {
  Notify ({type: 'success', message: 'Benchmark URL Copied.', duration:1500})
}

/* const selectedType = computed(() => {
    if(props.benchmarkItem.types.length) {
        return ref(props.benchmarkItem.types[0])
    }
    return ''
})
const dropdownOptions = computed(() => {
    let opts = [];
    if(props.benchmarkItem.types.length){
        opts = props.benchmarkItem.types.map((item) => {
            return {text: item, value: item}
        })
    }
    console.log("Data; ", opts)
    return opts
}) */

//Below function is unused as description is replaced with the captions in present benchmark tile
/* function clubCaptions(captions) {
    return captions.reduce((p, c, index) => {
        let retV = p + c;
        return index < (captions.length -1) ? retV + " | " : retV;
    }, "")
} */
</script>

<style >
</style>