<template>
  <div>
  <div class="tile-holder-benchmark w-100">
    <div class="tile-image-blue w-100 flex ac-c jc-c mb-10">
      <img :src="!props.testItem.image ? logo : imageServerUrl + props.testItem.image" >
    </div>
    <div class="tile-header pl-5 pr-5 mb-5">{{props.testItem.name}}</div>
    <div class="tile-sub-header pl-5 pr-5 mb-10">{{props.testItem.info}}</div>
    <!-- <div class="col-xs-3 tile-sub-header mb-5">{{props.testItem.type}}</div> -->
    <!--<div class="tile-sub-header pl-5 pr-5 mb-10">Result Types :</div>
    <div class="flex jc-fs ml-10 mr-5 mb-10">
      <span class="block-green mr-5 mb-5" v-for="(test, index) in props.testItem.resultTypes.slice(0,4)" :key="index">
        {{test}}
      </span>
      <span v-if="props.testItem.resultTypes.length > 3" class="block-dark-green mr-5 mb-5">
        {{props.testItem.resultTypes.length - 4}} more
      </span>
    </div>-->
    <div class="col-xs-3 tile-sub-header">Results:</div>
    <div class="tile-sub-header pl-5 pr-5 mb-20">
        <div class="pl-5" v-for="(item, key) in props.testItem.results.overall" :key="key">{{item.type}} : {{item.type == 'fps' ? item.value : item.value}}</div>
    </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, inject } from 'vue';

const props = defineProps(['testItem', 'index'])
const logo = require('@/assets/train-icon.png')
const imageServerUrl = inject('imageServerUrl')
const fpsList = props.testItem.results.data ?  props.testItem.results.data[0].values : []

// To Do, Remove below calculation as avgFPS is directly providing by test case itself
let avgFPS = 0
fpsList.forEach((item) => {
    avgFPS = avgFPS + item
})
avgFPS = avgFPS / fpsList.length

</script>
<style lang="scss" scoped>
.tileC{
    background-color: #fff;
}

.testLabel{
    color: #010101;
    font-family:Bold;
    font-size:20px;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.testLabel.mobile{
    width: 180px;
}
.primary{
    background-image: linear-gradient(to right, #f2f6de, #e0f2f4);
    border-radius: 10px 10px 0px 0px;
}
.secondary{
    background-image: linear-gradient(to right, #478fed, #1c63f3);
    border-radius: 10px 10px 0px 0px;
}
.infoC {
    width: 240px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Medium;
    color: #858585;
    font-size: 12px;
    padding-bottom: 10px;
}
.resultC {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Regular;
    padding-top: 5px;
    font-size: 15px;
    color: #353535;
}
.infoC.mobile{
    width:180px;
}
</style>