<template>
    <Transition name="modal-fade">
        <div class="popup-holder-showall">
            <div class="popup-inner w-80 p-30">
                <div class="flex col w-100">
                    <div class="flex center-xs bold p-10"> {{props.about}}</div>
                    <div class="flex filter-show-all p-20">
                        <div class="flex w-100 bold p-5">Filters</div>
                        <div class="flex row ml-0 mr-0 ml-20">'
                            <div class="flex"><van-field v-model="filters.name" name="name" label="Name" placeholder="Name of the Item"></van-field></div>
                            <div class="flex" v-if="props.about !== 'results'">
                                <van-checkbox v-model="filters.shared">Shared</van-checkbox>
                            </div>
                        </div>
                        <div class="flex col w-100 ml-20" v-if="props.about == 'devices'">
                             <div class="flex w-100 bold p-5">Device Filters</div>
                            <div class="flex row ml-0 mr-0">
                                <div class="flex"><van-field v-model="deviceFilters.type" name="type" label="Type" placeholder="Browser | STB"></van-field></div>
                                <div class="flex"><van-field v-model="deviceFilters.brand" name="brand" label="Brand" placeholder="Brand"></van-field></div>
                                <div class="flex"><van-field v-model="deviceFilters.model" name="model" label="Model" placeholder="Model"></van-field></div>
                                <div class="flex"><van-field v-model="deviceFilters.resolution" name="resolution" label="Resolution" placeholder="Resolution"></van-field></div>
                            </div>
                        </div>
                    </div>
                    <div class="flex pt-30">
                       <list :origin="props.about" :listItems="showData" @open-benchmark-from-tile="openBenchmark"></list>
                    </div>
                </div>

                 <div class="buttons flex jc-fe mt-20">
                    <div class="button-solid-grey-40 flex w-15 jc-c ai-c mr-15" @click="onCancel">
                        <span>Cancel</span>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
    <benchmark-edit-popup v-if="benchmark.editPopupVisible" :tileId="benchmark.tileId" @close="closeEditBenchmarkPopup"></benchmark-edit-popup>
</template>
<script setup>
import {defineProps, ref, reactive, watch, watchEffect, defineEmits} from 'vue'
import {List, BenchmarkEditPopup} from '@/components'

const props = defineProps(['data', 'about'])
const emit = defineEmits(['close', 'reloadData'])

const showData = ref(props.data)

const filters = reactive({
    shared: true,
    name: ''
})

const deviceFilters = reactive({
    type: '',
    brand: '',
    model: '',
    resolution: '',
})

watchEffect(async () => {
    showData.value = props.data.filter((item) => {
        return applyFiltersOnData(item, filters, deviceFilters)
    })
})

watch([filters,deviceFilters], async([nFV, nDFV]) => {
     showData.value = props.data.filter((item) => {
         return applyFiltersOnData(item, nFV, nDFV)
    })
})


function applyFiltersOnData(item, nFV, nDFV){
    let eligible = true;
    if(props.about == 'results') {
        eligible = item.benchmark.name.match(new RegExp(nFV.name, 'gi')) != null;
    } else {
        eligible =  nFV.name != '' ? item.isShared == nFV.shared && item.name.match(new RegExp(nFV.name, 'gi')) != null : item.isShared == nFV.shared;
    }
    if(eligible && props.about == 'devices'){
        eligible = nDFV.type != '' ? item.type.match(new RegExp(nDFV.type, 'gi')) != null : eligible
        eligible = eligible && nDFV.brand != '' ? item.brand.match(new RegExp(nDFV.brand, 'gi')) != null  : eligible
        eligible = eligible && nDFV.model != '' ? item.model.match(new RegExp(nDFV.model, 'gi')) != null : eligible
        eligible = eligible && nDFV.resolution != '' ? item.resolution.match(new RegExp(nDFV.resolution, 'gi')) != null : eligible
    }
    return eligible
}

// @todo: template $emit('close') resulting in vue waring, Extraneous non-emits event listeners
function onCancel(){
    emit('close')
}

const benchmark = reactive({
    editPopupVisible: false,
    tileId: ''
})
function openBenchmark(item){
    benchmark.tileId = item.id
    benchmark.editPopupVisible = true
}
function closeEditBenchmarkPopup(isBenchmarkUpdated){
     benchmark.editPopupVisible = false
     if(isBenchmarkUpdated) {
         emit('reloadData')
    }
}
</script>