<template>
  <div class="tile-result mb-30">
    <div class="tile-content">
      <div class="tile-score">
        {{getFormattedScore(props.result.overallScore)}}
        <div class="tile-compare" @click="compare">Compare</div>
        <div class="tile-details" @click="navigateToDetails">Details</div>
      </div>
      <div class="tile-title">{{props.result.benchmark.name || "no title"}}</div>
      <div class="tile-subtitle">{{props.result.device.browser.deviceModel || "no device model"}}</div>
      <div class="tile-results">
        <div class="tile-result-block">{{props.result.device.browser.name}}</div>
        <!-- <div class="tile-result-block">{{props.result.mp}} mp uploaded</div> -->
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, inject } from 'vue'
import router from '@/lib/router'
import { Notify  } from 'vant';

const props = defineProps(['result', 'index'])
function navigateToDetails(){
     router.push({path: `/results/${props.result.id}`})
}
function getFormattedScore(score) {
  return score ? score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 45;
}

const disableNonMVPFeatures = inject('disableNonMVPFeatures')
function compare(){
  disableNonMVPFeatures ? showNotify() : '' //Handle compare
}

const infoMessage = inject('disabledFeaturesMessage')
function showNotify() {
  Notify ({type: 'warning', message: infoMessage, duration:1500})
}
</script>