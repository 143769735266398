<template>
  <Transition name="modal-fade">
    <div class="popup-holder-benchmark">
      <div class="popup-inner w-50 p-30" @scroll="scrollStopper">
        <h2 class="mb-20">Create benchmark</h2>
        <div>
          <div class="popup-label-create ml-5">Name</div>
          <input class="input-name pl-15 mb-15 w-100" type="text" placeholder="Benchmark Name" v-model="data.name" @focusout="data.isInvalidName = data.name == ''">
          <div class="error-name pt-5 pb-5 pl-15 pr-15 mb-15 w-100" v-if="validations.isInvalidName">Benchmark name is required</div>
          <div class="popup-label-create ml-5 mt-5">Description</div>
          <input class="input-description pl-15 mb-15 w-100" type="text" placeholder="Description" v-model="data.info">
          <div class="popup-label-create ml-5 mt-5">Upload an image</div>
          <image-uploader @image-loaded="onImageLoaded"></image-uploader>
          <div class="popup-label-create ml-5 mt-5">Loop</div>
          <div class="slidecontainer">
            <input type="range" min="1" max="99" class="slider mt-5" v-model="data.loop">
            <span class="ml-10 caption">{{data.loop}}x</span>
          </div>
          <div class="popup-label-create ml-5 mt-10">Shared</div>
          <div class="checkbox-shared flex row ml-0">
            <input type="checkbox" name="shared" style="width:17px;height:17px" v-model="data.isShared">
            <label for="shared" class="pl-5 caption">will be visible to others and can be shared</label>
          </div>
          <div class="popup-label-create ml-5 mt-10">Selected tests
            <span class="block green ml-10">{{tests.selectedTests.length}}</span>
          </div>
          <div class="ml-10 mt-5 mb-5 caption">Tests that will be executed when running <span v-if="data.name" >"{{data.name}}"</span></div>
          <SlickList v-model:list="tests.selectedTests" axis="y" class="long-list drop-zone" group="tests">
            <SlickItem v-for="(item, index) in tests.selectedTests" class="item drag-el" :key="item.id" :index="index" :item="item">
              <test-list-tile :testItem="item" />
            </SlickItem>
          </SlickList>

          <div class="popup-label-create ml-5 mt-10">Available tests
            <span class="block red ml-10">{{tests.availableTests.length}}</span>
          </div>
          <div class="ml-10 mt-5 mb-5 caption">Tests that are available within the strike tool</div>
          <SlickList v-model:list="tests.availableTests" axis="y" class="long-list drop-zone" group="tests">
            <SlickItem v-for="(item, index) in tests.availableTests" class="item drag-el" :key="item.id" :index="index" :item="item">
              <test-list-tile :testItem="item" />
            </SlickItem>
          </SlickList>


          <div class="error-tests pt-5 pb-5 pl-15 pr-15 mb-15 mt-15 w-100" v-if="validations.isInvalidTests">Minimum of one test is required</div>
          <div class="buttons flex jc-fe mt-20">
            <div class="button-solid-grey-40 flex w-15 jc-c ai-c mr-15" @click="$emit('close')">
              <span>Cancel</span>
            </div>
            <div class="button-solid-black-40 flex w-15 jc-c ai-c" @click="createBenchmark()">
              <span>Save</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { SlickList, SlickItem } from 'vue-slicksort';
import {reactive, onBeforeMount, defineEmits} from 'vue'
import { post, get } from "@/lib/api.js"
import ImageUploader from "../../components/ImageUploader.vue"
import {TestListTile} from "@/components"

const emit = defineEmits(['close'])

const validations = reactive({
  isInvalidName: false,
  isInvalidTests: false,
  isInvalidData: function() {
    this.isInvalidTests = !tests.selectedTests.length
    this.isInvalidName = data.name == ''
    return (this.isInvalidTests || this.isInvalidName)
  }
})

const tests = reactive({
  availableTests: [],
  selectedTests: []
})

const data = reactive({
  name: '',
  info: '',
  isShared: false,
  loop: 1,
})


let imageObj;
function onImageLoaded(image){
  imageObj = image;
}

function scrollStopper(event){
  event.stopPropagation();
}

async function createBenchmark() {
  if (validations.isInvalidData()) {
    return
  }

  try {
    // upload image if available
    if(imageObj){
      const imageRes = await post('users/me/images/benchmark', true, imageObj);
      data.image = imageRes.data.imagePath
    }
    data.tests = tests.selectedTests.map( item => {
      return item.id;
    })

    // post benchmark
    const benchmark = await post('users/me/benchmarks', true, data);

    if(benchmark.status == "success") {
      emit('close');
    }
  } catch (error) {
    console.error('error adding benchmark:', error);
  }
}

onBeforeMount(async () => {
  try {
    let res = await get('users/me/tests', true)
    tests.availableTests = res.data
  }
  catch(error) {
    console.error("dataFetchError : " + error)
  }
})


</script>

<style scoped>
.drop-zone {
  padding: 20px;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}

.drag-el {
  margin-bottom: 10px;
}

::-webkit-scrollbar {
    display: none;
}

.item {
  z-index: 99999;
}

.slidecontainer {
  width: 40%;
}

.slider {
  -webkit-appearance: none;
  height: 12px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fafafa;
  border: 1px solid black;
  cursor: pointer;
}



.block {
    padding: 4px 10px 4px 10px;
    height: 12px;
    border-radius: 4px;
    font-size: 10px;
    color: white;
}
.green {
  background: #0f9f61;
}
.red {
  background: #ff645c;
}

.caption {
  color: #a8a8a8;
}
</style>