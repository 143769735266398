<template>
     <div class="test-result mb-30">
        <div class="tile-content flex">
            <div class="tile-image">
                <img class="blurred" :src="!test.image ? logo : imageServerUrl + test.image" alt="Test image" height="200"/>
                <img class="image" :src="!test.image ? logo : imageServerUrl + test.image" alt="Test image" height="200"/>
            </div>
            <div class="tile-meta">
                <div class="tile-title">{{test.name}}</div>
                <div class="results">
                    <div class="result" v-for="(test, index) in overallResults" :key="index">
                        {{test.value}} <span>{{test.label}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { defineProps, computed, inject} from 'vue'

const imageServerUrl = inject('imageServerUrl')
const props = defineProps(['test'])

const overallResults = computed(() => {
    return props.test.results.overall.map( item => {
        let {value, type} = item
        let label =  type === 'fps' ? 'Avg. FPS': type === 'memory' ? 'Megapixels' : 'Duration'
        value =  type === 'duration' ? new Date(value*1000).toISOString().slice(11,19) : value
        return {label, value}
    })
})
</script>