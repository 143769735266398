<template>
  <div class="footer flex w-100">
    <div class="flex w-100 flex col-xs-12">
      <div class="button-text-footer p-15" v-for="(footerItem, index) in footerItems" :key="`menu${index}`">
        {{footerItem.label}}
      </div>
      <div class="button-text-footer right p-15">
        Powered by Lightning™
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/lib/router';
import {footerItems} from '@/lib/utils'

export default {
  name: "FooterMenu",
  data(){
    return {
      activeMenuIndex: 0,
      footerItems
    }
  },
  methods:{
    goTo(item, ind){
      router.push({path: `home${item.path}`})
      this.activeMenuIndex = ind;
      this.expandMenu = false;
    }
  }
}
</script>