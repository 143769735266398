<template>
  <div class="flex w-100">
    <div class="flex col w-100 list">
      <div class="list-header-fame flex w-100 start-xs pl-5 pr-5 pt-15 pb-15">
        <div v-for="(item) in getProperties" :key="item" :class="`list-col-${item}`">
          {{item.toUpperCase()}}
        </div>
      </div>
      <div class="list-item-fame flex ac-c w-100 start-xs pl-5 pr-5" v-for="(dataItem, index) in getContent" :key="index">
        <div v-for="(item, i) in getProperties" :key="i" :class="`list-col-${item}`" @click="navigateToDetails(dataItem.id)">
          <template v-if="i===0">
            <span class="ranking mr-15" :class="index===0?`gold`:index===1?`silver`:index===2?`bronze`:`black`">
              {{index+1}}
            </span>
          </template>
          <template v-if="item === 'benchmark'">
            {{dataItem.name}}
          </template>
          <template v-if="item === 'points'">
            <span class="block" :class="dataItem.overallScore>40000?`green`:dataItem.overallScore>25000?`orange`:`red`">
              {{dataItem.overallScore}}
            </span>
          </template>
          <template v-else-if="item === 'device'">
            <span class="block">
              {{dataItem.deviceModel}}
            </span>
          </template>
          <template v-else-if="item === 'timestamp'">
            {{getFormattedTime(dataItem.testedAt)}}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { get } from "@/lib/api";
import { computed, reactive, onMounted, defineProps, onBeforeMount } from 'vue';
import router from '@/lib/router.js'

const props = defineProps(['path'])

const data = reactive({
    name: "ResultList",
    dataLoaded: false,
    results: []
})
onBeforeMount(async() => {
    try {
      const res = await get(props.path, props.path === 'users/me/results/top')
      data.results = res.data
    }
    catch(error){
        console.error("dataFetchError: " + error)
    }
})

// list header menu items class names matching its index number
// const headerClasses = ['col-xs-3', 'col-xs-1', 'col-xs-2', 'col-xs-3']

const getProperties = computed(() => {
    return ['benchmark', 'points', 'device', 'timestamp']
})

const getContent = computed(() => {
    return data.results
})

function navigateToDetails(resultId){
  router.push({path:`/results/${resultId}`})
}

const getFormattedTime = (date) => {
  if (date) {
    const d = new Date(date);
    return `${("0" + d.getDate()).slice(-2)}/${("0" + (d.getMonth() + 1)).slice(-2)}/${d.getFullYear()}`
  } else {
    return "Unknown"
  }
}

 onMounted(() => {
     setTimeout(() => {
        data.dataLoaded = true;
     }, 200);
 })
</script>